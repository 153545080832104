/*!***********************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./assets/styles/shop.scss ***!
  \***********************************************************************************************************************************************************************************************************************************************************************************************************/
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: white !important;
  background-color: unset !important;
}

.shop-footer {
  background-color: #02d256;
  color: #fff;
  width: 100%;
}
.shop-footer .next-btn {
  background-color: #fff;
  color: #000;
}

.event-image {
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.ticket, .drink {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-underline: none;
}
.ticket .package-image, .drink .package-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.drink-menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.drink-menu {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 1.5rem;
}
.drink-menu:hover {
  transform: scale(1.02);
}
.drink-menu .title {
  font-weight: 600;
  color: #fff;
  padding: 0.5rem;
}
.drink-menu .description {
  color: #fff;
  padding: 0.5rem;
  font-size: 14px;
}
.drink-menu .description * {
  font-size: 14px;
}
.drink-menu .drink-menu-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  width: 200px;
  height: 200px;
}
.drink-menu .drink-menu-image.logo {
  background-size: contain;
  aspect-ratio: 1;
}

.ticket.sold-out {
  border-color: darkred;
}

.lounge-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 2px solid green;
}
.lounge-card .lounge-image {
  width: 100%;
  max-height: 350px;
  aspect-ratio: 2/1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}
.lounge-card .lounge-image .lounge-name {
  display: flex;
  justify-content: center;
  align-items: end;
}
.lounge-card .lounge-image .lounge-name span {
  font-weight: 600;
  padding: 0rem 1.5rem;
}
.lounge-card .lounge-image .lounge-booking {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.lounge-card .lounge-image .lounge-booking button {
  background-color: green;
  color: #fff;
}
.lounge-card .lounge-image .lounge-infos {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.lounge-card.blocked {
  border: 2px solid darkred;
}

.lounge-card.statusUnknown {
  border: 0;
}

.times {
  justify-content: center;
}
.times .time-btn {
  padding: 1rem;
}
.package-list {
  display: flex;
  flex-wrap: wrap;
}

.package {
  width: 100%;
  border: 1px solid #fff;
  display: flex;
  flex-wrap: nowrap;
}
.package .package-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.pay-option {
  padding: 1rem;
}

.drink-menu-select {
  border: none !important;
  text-align: center;
  background-image: url("data:image/svg+xml, <svg width=%2720px%27 height=%2720px%27 version=%271.1%27 viewBox=%270 0 100 100%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill=%27white%27 d=%27m0 25 50 50 50-50z%27/></svg>") !important;
  color: #fff;
  position: relative;
  font-size: 18px !important;
}

div.drink-search-bar {
  width: 100%;
}
div.drink-search-bar input {
  background-image: none !important;
  border-color: var(--color-border);
}
div.drink-search-bar input:valid {
  border-color: var(--color-border) !important;
}

.drink-categories-tabs {
  display: -webkit-box;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 1.5rem;
}
.drink-categories-tabs .drink-categories-tab-item {
  padding: 0.5rem 0.35rem;
  margin: 0.5rem 0;
  cursor: pointer;
  display: block;
  color: #fff;
  background-color: var(--color-blackest);
  transition: all 0.3s ease-in-out;
}
.drink-categories-tabs .drink-categories-tab-item:hover {
  border-bottom: 2px solid var(--color-primary);
}
.drink-categories-tabs .drink-categories-tab-item.active {
  border-bottom: 2px solid var(--color-primary);
}
.drink-categories-tabs .drink-categories-tab-item-inner {
  width: 100%;
}
.drink-categories-tabs .drink-categories-tab-item-inner-title {
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: #fff;
  padding: 0.5rem;
}
/*!*****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/areas/area_layout.vue?vue&type=style&index=0&id=0c11eee6&scoped=true&lang=scss ***!
  \*****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.layout-plan[data-v-0c11eee6] {
  position: relative;
  width: 1000px;
}
@media (max-width: 992px) {
.layout-plan[data-v-0c11eee6] {
    width: 100%;
}
}
.area-hover[data-v-0c11eee6] {
  position: absolute;
  border: 2px solid var(--color-primary);
}
/*!*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/areas/area.vue?vue&type=style&index=0&id=5be47db3&scoped=true&lang=css ***!
  \*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.btn-default.active[data-v-5be47db3] {
    outline: solid var(--color-white);
}

/*!***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/oauth/Login.vue?vue&type=style&index=0&id=53a2a67a&scoped=true&lang=scss ***!
  \***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
label[data-v-53a2a67a] {
  text-align: left !important;
}
input.is-invalid[data-v-53a2a67a] {
  margin-bottom: 0.15rem;
}
.apple-sign-in[data-v-53a2a67a] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
}
.apple-sign-in[data-v-53a2a67a] {
  height: 50px !important;
  width: 300px !important;
  text-align: center;
  background-image: none !important;
  background-color: white !important;
  color: black !important;
}
body.active-light-mode .apple-sign-in[data-v-53a2a67a] {
  background-color: black !important;
  color: white !important;
}
.facebook-sign-in[data-v-53a2a67a] {
  height: 50px !important;
  width: 300px !important;
  background-color: #4267b2 !important;
  background-image: none !important;
}
/*!************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/oauth/register/step3.vue?vue&type=style&index=0&id=4c652ecc&scoped=true&lang=scss ***!
  \************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
/* purgecss start ignore */
.vue-advanced-cropper[data-v-4c652ecc] {
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  max-height: 100%;
  max-width: 100%;
  direction: ltr;
}
.vue-advanced-cropper__stretcher[data-v-4c652ecc] {
  pointer-events: none;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}
.vue-advanced-cropper__image[data-v-4c652ecc] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  transform-origin: center;
  max-width: none !important;
}
.vue-advanced-cropper__background[data-v-4c652ecc], .vue-advanced-cropper__foreground[data-v-4c652ecc] {
  opacity: 1;
  background: black;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.vue-advanced-cropper__foreground[data-v-4c652ecc] {
  opacity: 0.5;
}
.vue-advanced-cropper__boundaries[data-v-4c652ecc] {
  opacity: 1;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}
.vue-advanced-cropper__cropper-wrapper[data-v-4c652ecc] {
  width: 100%;
  height: 100%;
}
.vue-advanced-cropper__image-wrapper[data-v-4c652ecc] {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.vue-advanced-cropper__stencil-wrapper[data-v-4c652ecc] {
  position: absolute;
}
.vue-bounding-box[data-v-4c652ecc] {
  position: relative;
  height: 100%;
  width: 100%;
}
.vue-bounding-box__handler[data-v-4c652ecc] {
  position: absolute;
}
.vue-bounding-box__handler--west-north[data-v-4c652ecc] {
  left: 0;
  top: 0;
}
.vue-bounding-box__handler--north[data-v-4c652ecc] {
  left: 50%;
  top: 0;
}
.vue-bounding-box__handler--east-north[data-v-4c652ecc] {
  left: 100%;
  top: 0;
}
.vue-bounding-box__handler--east[data-v-4c652ecc] {
  left: 100%;
  top: 50%;
}
.vue-bounding-box__handler--east-south[data-v-4c652ecc] {
  left: 100%;
  top: 100%;
}
.vue-bounding-box__handler--south[data-v-4c652ecc] {
  left: 50%;
  top: 100%;
}
.vue-bounding-box__handler--west-south[data-v-4c652ecc] {
  left: 0;
  top: 100%;
}
.vue-bounding-box__handler--west[data-v-4c652ecc] {
  left: 0;
  top: 50%;
}
.vue-handler-wrapper[data-v-4c652ecc] {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}
.vue-handler-wrapper__draggable[data-v-4c652ecc] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vue-handler-wrapper--west-north[data-v-4c652ecc] {
  cursor: nw-resize;
}
.vue-handler-wrapper--north[data-v-4c652ecc] {
  cursor: n-resize;
}
.vue-handler-wrapper--east-north[data-v-4c652ecc] {
  cursor: ne-resize;
}
.vue-handler-wrapper--east[data-v-4c652ecc] {
  cursor: e-resize;
}
.vue-handler-wrapper--east-south[data-v-4c652ecc] {
  cursor: se-resize;
}
.vue-handler-wrapper--south[data-v-4c652ecc] {
  cursor: s-resize;
}
.vue-handler-wrapper--west-south[data-v-4c652ecc] {
  cursor: sw-resize;
}
.vue-handler-wrapper--west[data-v-4c652ecc] {
  cursor: w-resize;
}
.vue-handler-wrapper--disabled[data-v-4c652ecc] {
  cursor: auto;
}
.vue-draggable-area[data-v-4c652ecc] {
  position: relative;
}
.vue-preview-result[data-v-4c652ecc] {
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
}
.vue-preview-result__wrapper[data-v-4c652ecc] {
  position: absolute;
}
.vue-preview-result__image[data-v-4c652ecc] {
  pointer-events: none;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transform-origin: center;
  max-width: none !important;
}
.vue-preview[data-v-4c652ecc] {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.vue-preview--fill[data-v-4c652ecc] {
  width: 100%;
  height: 100%;
  position: absolute;
}
.vue-preview__wrapper[data-v-4c652ecc] {
  position: absolute;
  height: 100%;
  width: 100%;
}
.vue-preview__image[data-v-4c652ecc] {
  pointer-events: none;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transform-origin: center;
  max-width: none !important;
}
.vue-simple-line[data-v-4c652ecc] {
  background: none;
  transition: border 0.5s;
  border-color: rgba(255, 255, 255, 0.3);
  border-width: 0;
  border-style: solid;
}
.vue-simple-line--south[data-v-4c652ecc], .vue-simple-line--north[data-v-4c652ecc] {
  height: 0;
  width: 100%;
}
.vue-simple-line--east[data-v-4c652ecc], .vue-simple-line--west[data-v-4c652ecc] {
  height: 100%;
  width: 0;
}
.vue-simple-line--east[data-v-4c652ecc] {
  border-right-width: 1px;
}
.vue-simple-line--west[data-v-4c652ecc] {
  border-left-width: 1px;
}
.vue-simple-line--south[data-v-4c652ecc] {
  border-bottom-width: 1px;
}
.vue-simple-line--north[data-v-4c652ecc] {
  border-top-width: 1px;
}
.vue-simple-line--hover[data-v-4c652ecc] {
  opacity: 1;
  border-color: white;
}
.vue-line-wrapper[data-v-4c652ecc] {
  background: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vue-line-wrapper--north[data-v-4c652ecc], .vue-line-wrapper--south[data-v-4c652ecc] {
  height: 12px;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
}
.vue-line-wrapper--north[data-v-4c652ecc] {
  top: 0;
  cursor: n-resize;
}
.vue-line-wrapper--south[data-v-4c652ecc] {
  top: 100%;
  cursor: s-resize;
}
.vue-line-wrapper--east[data-v-4c652ecc], .vue-line-wrapper--west[data-v-4c652ecc] {
  width: 12px;
  height: 100%;
  transform: translateX(-50%);
  top: 0;
}
.vue-line-wrapper--east[data-v-4c652ecc] {
  left: 100%;
  cursor: e-resize;
}
.vue-line-wrapper--west[data-v-4c652ecc] {
  left: 0;
  cursor: w-resize;
}
.vue-line-wrapper--disabled[data-v-4c652ecc] {
  cursor: auto;
}
.vue-simple-handler[data-v-4c652ecc] {
  display: block;
  background: white;
  height: 10px;
  width: 10px;
}
.vue-rectangle-stencil[data-v-4c652ecc] {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.vue-rectangle-stencil__preview[data-v-4c652ecc] {
  position: absolute;
  width: 100%;
  height: 100%;
}
.vue-rectangle-stencil--movable[data-v-4c652ecc] {
  cursor: move;
}
.vue-circle-stencil[data-v-4c652ecc] {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  cursor: move;
}
.vue-circle-stencil__preview[data-v-4c652ecc] {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.vue-circle-stencil--movable[data-v-4c652ecc] {
  cursor: move;
}
.upload-cropper[data-v-4c652ecc] {
  border: solid 1px;
  min-height: 300px;
  min-width: 300px;
  max-height: 300px;
}
.upload-cropper__preview[data-v-4c652ecc] {
  border: solid 1px rgba(255, 255, 255, 0.15);
}
.button-wrapper[data-v-4c652ecc] {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}
.button[data-v-4c652ecc] {
  cursor: pointer;
}
.button input[data-v-4c652ecc] {
  display: none;
}

/* purgecss end ignore */
/*!************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/oauth/register/Register.vue?vue&type=style&index=0&id=5c023360&scoped=true&lang=css ***!
  \************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

/* purgecss start ignore */
.register-step-buttons[data-v-5c023360] {
  margin: 20px 0px;
}
#progressbar[data-v-5c023360] {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey
}
#progressbar .active[data-v-5c023360] {
    color: green;
}
#progressbar li[data-v-5c023360] {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    text-align: center;
}
#progressbar #account[data-v-5c023360]:before {
    content: ""
}
#progressbar #personal[data-v-5c023360]:before {
    content: ""
}
#progressbar #image[data-v-5c023360]:before {
    content: ""
}
#progressbar #confirm[data-v-5c023360]:before {
    content: ""
}
#progressbar li svg[data-v-5c023360] {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  color: white;
  margin: 0 auto 10px auto;
}
#progressbar li[data-v-5c023360]:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}
#progressbar li[data-v-5c023360]:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}
#progressbar li.active[data-v-5c023360]:before,
#progressbar li.active[data-v-5c023360]:after {
    background: #673AB7
}
@media only screen and (max-width: 767px) {
#progressbar[data-v-5c023360] {
     margin-bottom: 0px;
}
#progressbar li[data-v-5c023360]:before {
      width: 40px;
      height: 40px;
      line-height: 35px;
      font-size: 18px;
}
#progressbar li svg[data-v-5c023360] {
   top:10px;
   width:19px;
   height:19px;
}
#progressbar li[data-v-5c023360]:after {
    top: 19px;
}
}

/* purgecss end ignore */

/*!**************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/modules/Auth.vue?vue&type=style&index=0&id=99e55c1c&scoped=true&lang=css ***!
  \**************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.small-text[data-v-99e55c1c] {
  font-size: 15px !important;
  line-height: 22px !important;
}

/*!*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/packages/renderPackage.vue?vue&type=style&index=0&id=1971ca2a&scoped=true&lang=css ***!
  \*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.text-crossed[data-v-1971ca2a] {
  color: var(--color-danger);
  text-decoration: line-through;
}

/*!**************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/payment/index.vue?vue&type=style&index=0&id=47ef67f2&scoped=true&lang=css ***!
  \**************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.payment-methods {
.pay-option {
&[data-v-47ef67f2] {
        cursor: pointer
}
&.active[data-v-47ef67f2] {
            /* Add your active styles here */
}
}
}

/*!***********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/tickets/PersonalizedTicketsForm.vue?vue&type=style&index=0&id=04bb3c3c&scoped=true&lang=scss ***!
  \***********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.personalized-tickets .ticket-group[data-v-04bb3c3c] {
  margin-bottom: 2rem;
}
.personalized-tickets .ticket-form[data-v-04bb3c3c] {
  margin-bottom: 1.5rem;
}
.personalized-tickets .ticket-header[data-v-04bb3c3c] {
  margin-bottom: 1rem;
}
.personalized-tickets .ticket-fields[data-v-04bb3c3c] {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.personalized-tickets .field-group[data-v-04bb3c3c] {
  flex: 1 1 calc(50% - 0.5rem);
  min-width: 250px;
}
.personalized-tickets .field-group[data-v-04bb3c3c]:last-child {
  flex: 1 1 100%;
}
/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/tickets/renderTickets.vue?vue&type=style&index=0&id=51e276b2&scoped=true&lang=css ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.text-crossed[data-v-51e276b2] {
  color: var(--color-danger);
  text-decoration: line-through;
}

/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/checkout/CheckoutForm.vue?vue&type=style&index=0&id=12709848&scoped=true&lang=css ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.form-field[data-v-12709848] {
  margin-bottom: 1rem;
}
.col-lg-6[data-v-12709848]:not(:last-child) {
  padding-right: 1rem;
}
@media (max-width: 991.98px) {
.col-lg-6[data-v-12709848]:not(:last-child) {
    padding-right: 0;
}
}
.form-check-input[data-v-12709848] {
  margin-right: 0.5rem;
}
.text-danger[data-v-12709848] {
  color: var(--color-danger);
}
.is-valid[data-v-12709848] {
  border-color: var(--color-success);
}

/*!****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/checkout/InvoiceAddressForm.vue?vue&type=style&index=0&id=dc5d63c6&scoped=true&lang=css ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.form-field[data-v-dc5d63c6] {
  margin-bottom: 1rem;
}
.col-lg-6[data-v-dc5d63c6]:not(:last-child) {
  padding-right: 1rem;
}
@media (max-width: 991.98px) {
.col-lg-6[data-v-dc5d63c6]:not(:last-child) {
    padding-right: 0;
}
}

/*!***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/checkout/index.vue?vue&type=style&index=0&id=a07ffea0&scoped=true&lang=css ***!
  \***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.text-crossed[data-v-a07ffea0] {
    color: var(--color-danger);
    text-decoration: line-through;
}

/*!****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/drinks/index.vue?vue&type=style&index=0&id=7aaf9fc5&lang=scss&scoped=true ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.form-select[data-v-7aaf9fc5] {
  width: 100%;
  color: #fff;
}
/*!*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/preview.vue?vue&type=style&index=0&id=af7b2c4a&scoped=true&lang=scss ***!
  \*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.thumbnail[data-v-af7b2c4a] {
  position: relative;
}
.lounge-infos[data-v-af7b2c4a] {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-darker);
  padding: 1rem;
}
/*!****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/events/index.vue?vue&type=style&index=0&id=fd125efa&scoped=true&lang=scss ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.event-card[data-v-fd125efa] {
  border: 2px solid var(--color-lounge-available) !important;
}
.event-card.lounge-status-requests[data-v-fd125efa] {
  border: 2px solid var(--color-lounge-requests) !important;
}
.event-card.lounge-status-blocked[data-v-fd125efa] {
  border: 2px solid var(--color-lounge-blocked) !important;
}
.title[data-v-fd125efa] {
  font-size: 22px;
}
/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/AreaFilter.vue?vue&type=style&index=0&id=afa6fd7c&lang=scss&scoped=true ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.area-filter[data-v-afa6fd7c] {
  position: relative;
}
.area-filter .dropdown-toggle[data-v-afa6fd7c] {
  background: transparent;
  color: var(--color-white);
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1.5rem;
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  padding: 6px 12px;
}
.area-filter .dropdown-toggle[data-v-afa6fd7c]::after {
  margin-left: 0.5em;
  vertical-align: 0.2em;
}
.area-filter .dropdown-menu[data-v-afa6fd7c] {
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  background: var(--color-black);
  border: var(--border-width) solid var(--color-lighter);
}
.area-filter .dropdown-item[data-v-afa6fd7c] {
  color: var(--color-white);
  cursor: pointer;
  padding: 0.75rem 1rem;
  position: relative;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.area-filter .dropdown-item[data-v-afa6fd7c]:hover, .area-filter .dropdown-item[data-v-afa6fd7c]:focus {
  background-color: var(--color-primary);
}
.area-filter .dropdown-item .checkmark[data-v-afa6fd7c] {
  position: absolute;
  left: 1rem;
  font-size: 1.75rem;
}
.area-filter .dropdown-item .area-name[data-v-afa6fd7c] {
  margin-left: 2rem;
}
.area-filter .dropdown-item-list[data-v-afa6fd7c] {
  max-height: 200px;
  overflow-y: auto;
}
/*!************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/StatusFilter.vue?vue&type=style&index=0&id=4277a927&lang=scss&scoped=true ***!
  \************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.status-filter[data-v-4277a927] {
  position: relative;
}
.status-filter .dropdown-toggle[data-v-4277a927] {
  background: transparent;
  color: var(--color-white);
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1.5rem;
}
.status-filter .dropdown-toggle[data-v-4277a927]::after {
  margin-left: 0.5em;
  vertical-align: 0.2em;
}
.status-filter .dropdown-menu[data-v-4277a927] {
  max-width: 300px;
  max-height: 300px;
  overflow-y: auto;
  background: var(--color-black);
  border: var(--border-width) solid var(--color-lighter);
}
.status-filter .dropdown-item[data-v-4277a927] {
  color: var(--color-white);
  cursor: pointer;
  padding: 0.75rem 1rem;
  position: relative;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.status-filter .dropdown-item[data-v-4277a927]:hover, .status-filter .dropdown-item[data-v-4277a927]:focus {
  background-color: var(--color-primary);
}
.status-filter .dropdown-item .checkmark[data-v-4277a927] {
  position: absolute;
  left: 1rem;
  font-size: 1.75rem;
}
.status-filter .dropdown-item .status-name[data-v-4277a927] {
  padding-left: 2rem;
  padding-right: 2rem;
}
.status-filter .dropdown-item-list[data-v-4277a927] {
  max-height: 200px;
  overflow-y: auto;
}
/*!*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/filter/MobileFilter.vue?vue&type=style&index=0&id=03debd24&lang=scss&scoped=true ***!
  \*******************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.areas-grid[data-v-03debd24] {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  overflow-y: scroll;
}
.areas-grid-item[data-v-03debd24] {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  border: var(--border-width) solid var(--color-lighter);
  border-radius: var(--bs-border-radius-lg);
  background: var(--color-black);
}
.areas-grid-item.selected[data-v-03debd24] {
  border: var(--border-width) solid var(--color-primary);
}
.area-image[data-v-03debd24] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.area-name[data-v-03debd24] {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem;
  color: var(--color-white);
}
.drawer-trigger[data-v-03debd24] {
  border-radius: var(--bs-border-radius-lg);
  background-color: transparent;
  padding: 0.625rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-white);
  border: none;
}
.drawer-overlay[data-v-03debd24] {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1060;
}
.drawer-content[data-v-03debd24] {
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  border-top-left-radius: var(--bs-modal-border-radius-lg);
  border-top-right-radius: var(--bs-modal-border-radius-lg);
  height: 80%;
  margin-top: 6rem;
  max-height: 96%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1061;
}
.drawer-inner[data-v-03debd24] {
  padding: var(--bs-modal-padding-y) var(--bs-modal-padding-x);
  background-color: var(--color-black);
  border-top-left-radius: var(--bs-modal-border-radius-lg);
  border-top-right-radius: var(--bs-modal-border-radius-lg);
  flex: 1;
  overflow: scroll;
  height: 100%;
}
.drawer-handle[data-v-03debd24] {
  margin: 0 auto;
  width: 3rem;
  height: 0.375rem;
  flex-shrink: 0;
  border-radius: 9999px;
  background-color: var(--bs-gray-300);
  margin-bottom: 2rem;
}
.drawer-content-wrapper[data-v-03debd24] {
  padding: 1rem;
  margin: 0 auto;
}
/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-4.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-4.use[2]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/lounges/reservationRequests/index.vue?vue&type=style&index=0&id=7371b586&scoped=true&lang=css ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/

.form-group textarea[data-v-7371b586] {
    resize: both !important;
    overflow: hidden !important;
    width: 100% !important;
    min-height: 3em !important;
    max-height: unset !important;
}

/*!****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/payment/paymentCompleted.vue?vue&type=style&index=0&id=2a54f875&scoped=true&lang=scss ***!
  \****************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.payment-completed-modal[data-v-2a54f875] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  height: 100%; /* Use full height of the parent container */
  box-sizing: border-box;
}
.status-icon[data-v-2a54f875] {
  width: 120px;
  height: 120px;
  margin-bottom: 2rem;
}
.status-title[data-v-2a54f875] {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.status-message[data-v-2a54f875] {
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  max-width: 80%;
}
.action-button[data-v-2a54f875] {
  padding: 1.25rem 2.5rem; /* Larger button */
  font-size: 1.25rem; /* Larger font size */
  font-weight: bold;
  color: white;
  background-color: var(--color-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.action-button[data-v-2a54f875]:hover {
  background-color: var(--color-primary-dark);
  transform: translateY(-2px);
}

/* Checkmark animation */
.checkmark__circle[data-v-2a54f875] {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke-2a54f875 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__check[data-v-2a54f875] {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke: #7ac142;
  stroke-width: 2;
  fill: none;
  animation: stroke-2a54f875 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke-2a54f875 {
100% {
    stroke-dashoffset: 0;
}
}
/* Pending animation */
.pending circle[data-v-2a54f875] {
  stroke: #ffa500;
  stroke-width: 4;
  fill: none;
}
.pending__hand[data-v-2a54f875] {
  stroke: #ffa500;
  stroke-width: 4;
  fill: none;
  transform-origin: 50% 50%;
  animation: rotate-2a54f875 2s linear infinite;
}
@keyframes rotate-2a54f875 {
100% {
    transform: rotate(360deg);
}
}
/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/user/details/index.vue?vue&type=style&index=0&id=0ce36430&scoped=true&lang=scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
/*! purgecss start ignore */
.vue-advanced-cropper[data-v-0ce36430] {
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  max-height: 100%;
  max-width: 100%;
  direction: ltr;
}
.vue-advanced-cropper__stretcher[data-v-0ce36430] {
  pointer-events: none;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}
.vue-advanced-cropper__image[data-v-0ce36430] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  transform-origin: center;
  max-width: none !important;
}
.vue-advanced-cropper__background[data-v-0ce36430],
.vue-advanced-cropper__foreground[data-v-0ce36430] {
  opacity: 1;
  background: black;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.vue-advanced-cropper__foreground[data-v-0ce36430] {
  opacity: 0.5;
}
.vue-advanced-cropper__boundaries[data-v-0ce36430] {
  opacity: 1;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}
.vue-advanced-cropper__cropper-wrapper[data-v-0ce36430] {
  width: 100%;
  height: 100%;
}
.vue-advanced-cropper__image-wrapper[data-v-0ce36430] {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.vue-advanced-cropper__stencil-wrapper[data-v-0ce36430] {
  position: absolute;
}
.vue-bounding-box[data-v-0ce36430] {
  position: relative;
  height: 100%;
  width: 100%;
}
.vue-bounding-box__handler[data-v-0ce36430] {
  position: absolute;
}
.vue-bounding-box__handler--west-north[data-v-0ce36430] {
  left: 0;
  top: 0;
}
.vue-bounding-box__handler--north[data-v-0ce36430] {
  left: 50%;
  top: 0;
}
.vue-bounding-box__handler--east-north[data-v-0ce36430] {
  left: 100%;
  top: 0;
}
.vue-bounding-box__handler--east[data-v-0ce36430] {
  left: 100%;
  top: 50%;
}
.vue-bounding-box__handler--east-south[data-v-0ce36430] {
  left: 100%;
  top: 100%;
}
.vue-bounding-box__handler--south[data-v-0ce36430] {
  left: 50%;
  top: 100%;
}
.vue-bounding-box__handler--west-south[data-v-0ce36430] {
  left: 0;
  top: 100%;
}
.vue-bounding-box__handler--west[data-v-0ce36430] {
  left: 0;
  top: 50%;
}
.vue-handler-wrapper[data-v-0ce36430] {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}
.vue-handler-wrapper__draggable[data-v-0ce36430] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vue-handler-wrapper--west-north[data-v-0ce36430] {
  cursor: nw-resize;
}
.vue-handler-wrapper--north[data-v-0ce36430] {
  cursor: n-resize;
}
.vue-handler-wrapper--east-north[data-v-0ce36430] {
  cursor: ne-resize;
}
.vue-handler-wrapper--east[data-v-0ce36430] {
  cursor: e-resize;
}
.vue-handler-wrapper--east-south[data-v-0ce36430] {
  cursor: se-resize;
}
.vue-handler-wrapper--south[data-v-0ce36430] {
  cursor: s-resize;
}
.vue-handler-wrapper--west-south[data-v-0ce36430] {
  cursor: sw-resize;
}
.vue-handler-wrapper--west[data-v-0ce36430] {
  cursor: w-resize;
}
.vue-handler-wrapper--disabled[data-v-0ce36430] {
  cursor: auto;
}
.vue-draggable-area[data-v-0ce36430] {
  position: relative;
}
.vue-preview-result[data-v-0ce36430] {
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
}
.vue-preview-result__wrapper[data-v-0ce36430] {
  position: absolute;
}
.vue-preview-result__image[data-v-0ce36430] {
  pointer-events: none;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transform-origin: center;
  max-width: none !important;
}
.vue-preview[data-v-0ce36430] {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.vue-preview--fill[data-v-0ce36430] {
  width: 100%;
  height: 100%;
  position: absolute;
}
.vue-preview__wrapper[data-v-0ce36430] {
  position: absolute;
  height: 100%;
  width: 100%;
}
.vue-preview__image[data-v-0ce36430] {
  pointer-events: none;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transform-origin: center;
  max-width: none !important;
}
.vue-simple-line[data-v-0ce36430] {
  background: none;
  transition: border 0.5s;
  border-color: rgba(255, 255, 255, 0.3);
  border-width: 0;
  border-style: solid;
}
.vue-simple-line--south[data-v-0ce36430],
.vue-simple-line--north[data-v-0ce36430] {
  height: 0;
  width: 100%;
}
.vue-simple-line--east[data-v-0ce36430],
.vue-simple-line--west[data-v-0ce36430] {
  height: 100%;
  width: 0;
}
.vue-simple-line--east[data-v-0ce36430] {
  border-right-width: 1px;
}
.vue-simple-line--west[data-v-0ce36430] {
  border-left-width: 1px;
}
.vue-simple-line--south[data-v-0ce36430] {
  border-bottom-width: 1px;
}
.vue-simple-line--north[data-v-0ce36430] {
  border-top-width: 1px;
}
.vue-simple-line--hover[data-v-0ce36430] {
  opacity: 1;
  border-color: white;
}
.vue-line-wrapper[data-v-0ce36430] {
  background: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vue-line-wrapper--north[data-v-0ce36430],
.vue-line-wrapper--south[data-v-0ce36430] {
  height: 12px;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
}
.vue-line-wrapper--north[data-v-0ce36430] {
  top: 0;
  cursor: n-resize;
}
.vue-line-wrapper--south[data-v-0ce36430] {
  top: 100%;
  cursor: s-resize;
}
.vue-line-wrapper--east[data-v-0ce36430],
.vue-line-wrapper--west[data-v-0ce36430] {
  width: 12px;
  height: 100%;
  transform: translateX(-50%);
  top: 0;
}
.vue-line-wrapper--east[data-v-0ce36430] {
  left: 100%;
  cursor: e-resize;
}
.vue-line-wrapper--west[data-v-0ce36430] {
  left: 0;
  cursor: w-resize;
}
.vue-line-wrapper--disabled[data-v-0ce36430] {
  cursor: auto;
}
.vue-simple-handler[data-v-0ce36430] {
  display: block;
  background: white;
  height: 10px;
  width: 10px;
}
.vue-rectangle-stencil[data-v-0ce36430] {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.vue-rectangle-stencil__preview[data-v-0ce36430] {
  position: absolute;
  width: 100%;
  height: 100%;
}
.vue-rectangle-stencil--movable[data-v-0ce36430] {
  cursor: move;
}
.vue-circle-stencil[data-v-0ce36430] {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  cursor: move;
}
.vue-circle-stencil__preview[data-v-0ce36430] {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.vue-circle-stencil--movable[data-v-0ce36430] {
  cursor: move;
}

/*! purgecss end ignore */
/*! purgecss start ignore */
.upload-cropper[data-v-0ce36430] {
  border: solid 1px;
  min-height: 300px;
  min-width: 300px;
  max-height: 300px;
}
.upload-cropper__preview[data-v-0ce36430] {
  border: solid 1px rgba(255, 255, 255, 0.15);
}
.button-wrapper[data-v-0ce36430] {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}
.button[data-v-0ce36430] {
  cursor: pointer;
}
.button input[data-v-0ce36430] {
  display: none;
}

/*! purgecss end ignore */
/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/components/widgets/userWidget.vue?vue&type=style&index=0&id=50c03cb6&scoped=true&lang=scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
/* purgecss start ignore */
.login-btn[data-v-50c03cb6] {
  cursor: pointer;
  height: 50px;
  width: 100%;
  color: var(--color-white);
  background-color: transparent;
  background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary) 90%);
  border-radius: var(--radius);
  border-style: solid;
  border-width: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none !important;
  text-shadow: var(--shadow-light);
  line-height: 47px;
  padding: 0 28px;
  transition: 0.4s;
}
.login-btn[data-v-50c03cb6]:hover {
  box-shadow: var(--shadow-primary);
  filter: brightness(125%);
  color: var(--color-white);
  text-decoration: none;
}
#userWidget[data-v-50c03cb6], #userWidgetMobile[data-v-50c03cb6] {
  position: relative;
  cursor: pointer;
}
#userWidget .user-card .user-image[data-v-50c03cb6], #userWidgetMobile .user-card .user-image[data-v-50c03cb6] {
  float: left;
  width: 25px;
  height: 25px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}
#userWidget .user-card .user-placeholder[data-v-50c03cb6], #userWidgetMobile .user-card .user-placeholder[data-v-50c03cb6] {
  background-image: url(/build/images/user_image_placeholder.1956bf01.png);
}
#userWidget .user-card .user-info[data-v-50c03cb6], #userWidgetMobile .user-card .user-info[data-v-50c03cb6] {
  float: left;
  line-height: 25px;
  color: #fff !important;
}
#userWidget .side-menu-user-card[data-v-50c03cb6], #userWidgetMobile .side-menu-user-card[data-v-50c03cb6] {
  padding: 0px 20px;
}
#userWidget .side-menu-user-card .user-image[data-v-50c03cb6], #userWidgetMobile .side-menu-user-card .user-image[data-v-50c03cb6] {
  width: 55px;
  height: 55px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}
#userWidget .side-menu-user-card .user-placeholder[data-v-50c03cb6], #userWidgetMobile .side-menu-user-card .user-placeholder[data-v-50c03cb6] {
  background-image: url(/build/images/user_image_placeholder.1956bf01.png);
}
#userWidget .side-menu-user-card .user-info[data-v-50c03cb6], #userWidgetMobile .side-menu-user-card .user-info[data-v-50c03cb6] {
  float: left;
  line-height: 25px;
  color: #fff !important;
}
#userWidget .side-menu-user-card .side-menu-user-edit a[data-v-50c03cb6], #userWidgetMobile .side-menu-user-card .side-menu-user-edit a[data-v-50c03cb6] {
  color: #fff;
  font-size: 14px;
}
#userWidget .side-menu-user-card .side-menu-user-edit a[data-v-50c03cb6]:hover, #userWidgetMobile .side-menu-user-card .side-menu-user-edit a[data-v-50c03cb6]:hover {
  color: var(--color-darkest);
}
#userWidget .user-submenu[data-v-50c03cb6], #userWidgetMobile .user-submenu[data-v-50c03cb6] {
  min-width: 240px;
  height: auto;
  position: absolute;
  margin-top: 35px;
  margin-left: -15px;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  padding: 12px 0;
  transition: 0.3s;
  border-radius: 10px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}
#userWidget .user-submenu li[data-v-50c03cb6], #userWidgetMobile .user-submenu li[data-v-50c03cb6] {
  font-weight: 500;
  padding: 5px 20px;
  font-size: 14px;
  display: block;
  color: var(--color-body);
  margin: 0 10px;
  border-radius: 3px;
  transition: 0.3s;
}
#userWidget .user-submenu li[data-v-50c03cb6]:hover, #userWidgetMobile .user-submenu li[data-v-50c03cb6]:hover {
  color: var(--color-primary);
  background: var(--color-darker);
}
#userWidget .user-submenu .logout a[data-v-50c03cb6], #userWidgetMobile .user-submenu .logout a[data-v-50c03cb6] {
  color: red;
}
#userWidget:hover .user-submenu[data-v-50c03cb6], #userWidgetMobile:hover .user-submenu[data-v-50c03cb6] {
  opacity: 1;
  visibility: visible;
}

/* purgecss end ignore */
/*!**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!./node_modules/vue-loader/dist/stylePostLoader.js!./node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-13.use[2]!./node_modules/resolve-url-loader/index.js??clonedRuleSet-13.use[3]!./node_modules/sass-loader/dist/cjs.js??clonedRuleSet-13.use[4]!./node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./assets/vue/src/index.vue?vue&type=style&index=0&id=dc79ecc0&scoped=true&lang=scss ***!
  \**********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
.back-button[data-v-dc79ecc0] {
  height: 30px;
  width: 30px;
  font-size: 24px;
  background: none;
  color: var(--color-body);
  cursor: pointer;
}
